import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import '../Footer/Footer.scss';

export default function Footer() {
  return (
    <footer className='footer-section'>
      <Container>
          <Row className='gy-5'>
            <Col md={3} sm={6}>
              <div className='footer-logo'>
                <h2>LogoHere</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
            </Col>
            <Col md={3} sm={6}>
              <div className='footer-menus'>
                <h2>Quick Links</h2>
                <ul>
                  <li>
                    <a className="nav-link" href="#EasyToStartPlay">How to start</a>
                  </li>
                  <li>
                    <a className="nav-link" href="#HowToPlay">How to play</a>
                  </li>
                  <li>
                    <a className="nav-link" href="#Feedbacks">Out customers</a>
                  </li>
                  <li>
                    <a className="nav-link" href="#faq">Faq's</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3} sm={6}>
              <div className='payment-partners'>
                <h2>Our Payment Partners</h2>
                <ul>
                  <li>
                      <img src='https://housie.live/assets/images/visa-logo.svg' />
                  </li>
                  <li>
                      <img src='https://housie.live/assets/images/mastercard-logo.svg' />
                  </li>
                  <li>
                      <img src='https://housie.live/assets/images/google-pay-icon.svg' />
                  </li>
                  <li>
                      <img src='https://housie.live/assets/images/phonepe-logo-icon.svg' />
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3} sm={6}>
              <div className='socialmedia'>
                <h2>Stay Conncected</h2>
                <ul>
                  <li>
                    <a className="media-link facebook" href="#EasyToStartPlay">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a className="media-link twitter" href="#HowToPlay">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a className="media-link linkedin" href="#Feedbacks">
                      <FaLinkedinIn /> 
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
      </Container>
    </footer>
  )
}
