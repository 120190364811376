import React, {useEffect} from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
// import HeroImg from '../../assets/images/hero-img.png';
import AppleStore from '../../assets/images/app-store.svg';
import GooglePlay from '../../assets/images/google-play.svg';

export default function HeroCommonSlide({title, discription, appleIcon, GooglePlayIcon, HeroImg, AppleStoreUrl, GooglePlayUrl}) {   
  return (
    <section id="Home" className='w-100'>
    <div className="fantasytitle">FANTASY</div>
        <Container>
            <Row className='align-items-center justify-content-between'>  
                <Col md={6} className="order-md-2 d-none d-md-block">
                <div className="hero-img position-relative text-center">
                    <img className='img-fluid m-auto' src={HeroImg} />
                </div>
                </Col>             
                <Col md={6} className="order-md-1">
                <div className="hero-content position-relative">
                    <h1 className='fadeInRight'>{title}</h1>
                    <p className='fadeInUp delay-2s'>{discription}</p>
                    <ul className='fadeInUp delay-3s'>
                        <li>
                            <a href={AppleStoreUrl} target="_blank">
                                <img className='img-fluid m-auto' src={appleIcon} />
                            </a>
                        </li>
                        <li>
                            <a href={GooglePlayUrl} target="_blank">
                                <img className='img-fluid m-auto' src={GooglePlayIcon} />
                            </a>
                        </li>
                    </ul>
                    <div className="get-download-link d-none">
                        <Form action=''>
                        <Form.Group className="w-100" controlId="exampleForm.ControlInput1">
                            <Form.Control className='form-control border-0' type="text" placeholder="Enter you mobile number" />
                        </Form.Group>
                            <Button className='btn btn-primary' type="button" variant="outline-primary">
                                <span>Get App Link</span>
                            </Button>
                        </Form>
                    </div>
                </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
