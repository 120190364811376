import React from 'react'
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap'
import { FaStar } from 'react-icons/fa';
import '../Testimonials/Testimonials.scss'
import TestimonialAuthor1 from '../../assets/images/testimonial-author-1.png';

export default function Testimonials() {

    var TestimonialsSlider = {
        dots: false,
        infinite: true,
        arrows:true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
      };

  return (
    <section id='Feedbacks' className='testimonials-section'>
        <Container>
            <Row>
                <Col md={12}>
                    <div className="main-title">
                        <h2>What’s Our Customer Say</h2>
                        <p>Labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    </div>
                </Col>
                <Col md={12}>
                <Slider {...TestimonialsSlider}>
                    <div className='single-testimonial'>
                        <div className='feedback-starts'>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </div>
                        <div className='testimonial-text'>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum cumsan lacus vel facilisis.’’</p>
                        </div>
                        <div className='testimonial-userinfo'>
                            <div className='client-name'>
                                <h3>Jonah D. Kwon</h3>
                                <p>CEO / Founder</p>
                            </div>
                            <div className='client-img'>
                                <img src={TestimonialAuthor1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='single-testimonial'>
                        <div className='feedback-starts'>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </div>
                        <div className='testimonial-text'>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum cumsan lacus vel facilisis.’’</p>
                        </div>
                        <div className='testimonial-userinfo'>
                            <div className='client-name'>
                                <h3>Jonah D. Kwon</h3>
                                <p>CEO / Founder</p>
                            </div>
                            <div className='client-img'>
                                <img src={TestimonialAuthor1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='single-testimonial'>
                        <div className='feedback-starts'>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </div>
                        <div className='testimonial-text'>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum cumsan lacus vel facilisis.’’</p>
                        </div>
                        <div className='testimonial-userinfo'>
                            <div className='client-name'>
                                <h3>Jonah D. Kwon</h3>
                                <p>CEO / Founder</p>
                            </div>
                            <div className='client-img'>
                                <img src={TestimonialAuthor1} alt="" />
                            </div>
                        </div>
                    </div>
                </Slider>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
