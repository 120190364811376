import React from 'react'
import Slider from "react-slick";
import { Container } from 'react-bootstrap';
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../OurPartners/OurPartners.scss"
import Airbnb from '../../assets/images/airbnb.png';
import Google from '../../assets/images/google.png';
import Hubspot from '../../assets/images/hubspot.png';
import Microsoft from '../../assets/images/microsoft.png';
import Walmart from '../../assets/images/walmart.png';
import FedEx from '../../assets/images/fedex.png';

export default function OurPartners() {
    var OurPartnersSlider = {
        dots: false,
        infinite: true,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 360,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
      };
  return (
    <section className='partners-section'>
        <Container>
            <Slider {...OurPartnersSlider}>
                <div className='partner-logo'>
                    <img src={Airbnb} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={Google} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={Hubspot} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={Microsoft} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={Walmart} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={FedEx} alt="" />
                </div>
            </Slider>
        </Container>
    </section>
  )
}
