import React from 'react'
import AboutUsSection from '../AboutUsSection/AboutUsSection'
import EasyToStartPlay from '../EasyToStartPlay/EasyToStartPlay'
import Faq from '../Faq/Faq'
import GetDoloadLink from '../GetDowloadLink/GetDoloadLink'
// import HeroSection from './components/MainSlider/HeroSection';
import HeroSection from '../MainSlider/HeroSection'
import OurPartners from '../OurPartners/OurPartners'
import Testimonials from '../Testimonials/Testimonials'

export default function MainWrapper() {
  return (
    <>
        {/* <HeroSection /> */}
        <HeroSection />
        <OurPartners />
        <EasyToStartPlay />
        <GetDoloadLink />
        <AboutUsSection />
        <Testimonials />
        <Faq />
    </>    
  )
}
