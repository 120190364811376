import Header from './common/Header/Header';
import './assets/css/mixins/Mixins.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import MainWrapper from './components/MainWrapper/MainWrapper';
import Footer from './common/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <MainWrapper />
      <Footer />
    </div>
  );
}

export default App;
