import React from 'react'
// import Slider from "react-slick";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap';
import '../MainSlider/HeroSection.scss';
import HeroImg from '../../assets/images/hero-img.png';
// import HeroImg2 from '../../assets/images/hero-img2.png';
import AppleStore from '../../assets/images/app-store.svg';
import GooglePlay from '../../assets/images/google-play.svg';
import HeroCommonSlide from '../HeroCommonSlide/HeroCommonSlide';

export default function HeroSection() {
    var HeroSlider = {
        dots: true,
        infinite: true,
        arrows:false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
            
  return (
    <>
        <Slider className='hero-section' {...HeroSlider}>

        {/* <HeroCommonSlide HeroImg={HeroImg}  title="Fantasy Sports App Developments" discription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." /> */}
        <HeroCommonSlide HeroImg={HeroImg} GooglePlayIcon={GooglePlay} appleIcon={AppleStore} GooglePlayUrl="https://play.google.com/store/apps/details?id=com.com2us.chronicles.android.google.us.normal" AppleStoreUrl="https://www.apple.com/in/itunes" title="Fantasy Sports App Developments" discription="1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
        {/* <HeroCommonSlide HeroImg={HeroImg2} GooglePlayIcon={GooglePlay} appleIcon={AppleStore} GooglePlayUrl="https://play.google.com/store/apps/details?id=com.com2us.chronicles.android.google.us.normal" AppleStoreUrl="https://www.apple.com/in/itunes" title="Lorem Ipsum is simply dummy text of the printing." discription="2 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
        <HeroCommonSlide HeroImg={HeroImg} GooglePlayIcon={GooglePlay} appleIcon={AppleStore} GooglePlayUrl="https://play.google.com/store/apps/details?id=com.com2us.chronicles.android.google.us.normal" AppleStoreUrl="https://www.apple.com/in/itunes" title="2 Fantasy Sports App Developments" discription="3 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." /> */}
        
        {/* <div className='w-100'>
        <Container>
            <Row className='align-items-center justify-content-between'>               
                <Col md={6}>
                <div className="hero-content position-relative">
                    <h1>Fantasy Sports App Development</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <ul>
                        <li>
                            <a href="" target="_blank">
                                <img className='img-fluid m-auto' src={AppleStore} />
                            </a>
                        </li>
                        <li>
                            <a href="" target="_blank">
                                <img className='img-fluid m-auto' src={GooglePlay} />
                            </a>
                        </li>
                    </ul>
                </div>
                </Col>
                <Col md={6}>
                <div className="hero-img position-relative text-center">
                   <img className='img-fluid m-auto' src={HeroImg} />
                </div>
                </Col>
            </Row>
       </Container>
       </div> */}
        </Slider>
    
    
    <div className="hero-section d-flex align-items-center justify-content-between d-none">
       <Container>
            <Row className='align-items-center justify-content-between g-5'>
                {/* <Col md={12}>
                <div className="hero-content position-relative text-center">
                    <h1>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
                </Col> */}
                <Col md={6}>
                <div className="hero-content position-relative">
                    <h1>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
                </Col>
                <Col md={6}>
                <div className="hero-img position-relative">
                   <img src={HeroImg} />
                </div>
                </Col>
            </Row>
       </Container>
    </div>    
    </>
  )
}
